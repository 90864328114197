import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Button, Space } from 'antd';
import {
  ContainerOutlined,
  DatabaseOutlined,
  LogoutOutlined,
} from '@ant-design/icons';

import _ from 'lodash';

import { withContext } from '@/hoc';
import TABLE from '@/table';

export default
@withContext
class extends React.Component {
  state = {

    menuTree: [],
  };

  renderMenu(list) {
    return _.map(list, o => _.size(o.children) ? (
      <Menu.SubMenu key={o.id} icon={o.icon} title={o.name}>
        {this.renderMenu(o.children)}
      </Menu.SubMenu>
    ) : (
      <Menu.Item key={o.href} icon={o.icon}>
        <Link to={o.href}>{o.name}</Link>
      </Menu.Item>
    ))
  }
  
  render() {
    const { user } = this.props.context;
    
    return (
      <div style={{position: 'relative', maxWidth: '1024px', margin: '0 auto'}}>
        {!!user && (<Button style={{position: 'absolute', top: 10, right: 10, zIndex: 10}} onClick={async () => {
          try {
            await this.props.context.confirm('确认退出登录');
            localStorage.clear();
            this.props.setContext({ user: null })
            this.props.context.success('退出成功');
          } catch(e) {
            this.props.context.handleException(e);
          }
        }} icon={<LogoutOutlined />}></Button>)}
        <div>
          {this.props.children}
        </div>
      </div>
    )
  }
}