import React from 'react';
import { Button, Space, Form, Input, Drawer } from 'antd';

import _ from 'lodash';

import { ImageUpload, ProductGroupSelect, RichEditor } from '@/component';
import TABLE from '@/table';
import { withContext } from '@/hoc';

export default
@withContext
class extends React.Component {
  state = {
    form: {
      visible: false,
    },
    submitButtonLoading: false,
  }
  formRef = React.createRef();
  render() {
    const { okText, cancelText, ...props } = this.props;
    const { form, submitButtonLoading } = this.state;
    return (
      <>
        <Button onClick={this.showForm} {..._.pick(props, ['icon', 'style', 'type'])}>{this.props.children}</Button>
        
        <Drawer
          className="label-100px"
          destroyOnClose
          onClose={this.hideForm}
          {..._.pick(form, ['width', 'visible', 'title'])}
          footer={
            <div style={{ textAlign: 'right' }}>
              <Space>
                <Button onClick={this.hideForm}>{cancelText || '取消'}</Button>
                <Button loading={submitButtonLoading} onClick={() => this.formRef.current.submit()} type="primary">{okText || '确定'}</Button>
              </Space>
            </div>
          }
        >
          <Form
            ref={this.formRef}
            {..._.pick(form, ['initialValues', 'onFinish', 'onValuesChange'])}
            className="label-100px"
          >
            <Form.Item label="数据名称" name={TABLE.PRODUCT.NAME} rules={[{ required: true, message: '请输入数据名称!' }]}>
              <Input />
            </Form.Item>

            <Form.Item label="数据分组" name={TABLE.PRODUCT.PRODUCT_GROUP} rules={[{ required: true, message: '请选择数据分组!' }]}>
              <ProductGroupSelect changeOnSelect />
            </Form.Item>

            <Form.Item label="数据描述" name={TABLE.PRODUCT.DESCRIPTION}>
              <Input />
            </Form.Item>

            <Form.Item label="数据图片" name={TABLE.PRODUCT.IMAGE}>
              <ImageUpload placeholder="上传图片" />
            </Form.Item>

            <Form.Item label="数据详情" name={TABLE.PRODUCT.CONTENT}>
              <RichEditor />
            </Form.Item>

          </Form>
        </Drawer>
      </>
    )
  }

  onFinish = formValues => {
    const { onChange, onFinish } = this.props;
    
    if(onFinish) {
      onFinish(formValues, {
        hideForm: this.hideForm,
        submitButtonLoading: this.submitButtonLoading
      })
    } else if(onChange) {
      onChange(formValues);
      this.hideForm()
    }
  }

  hideForm = () => {
    const { form } = this.state;
    this.setState({ form: { ...form, visible: false }})
  }

  submitButtonLoading = submitButtonLoading => this.setState({ submitButtonLoading })
  
  showForm = async () => {
    const { value, title } = this.props;
    
    try {
      const initialValues = (value && value.id) ? await (async () => {
        const [object] = await this.props.context.request(
          ['product.find', { id: value.id }]
        );
        object[TABLE.PRODUCT.PRODUCT_GROUP] = _.get(_.last(object[TABLE.PRODUCT.PRODUCT_GROUP]), 'id');
        return object;
      })() : { ...value };

      this.formRef.current?.resetFields();
      this.formRef.current?.setFieldsValue(initialValues);
      this.setState({
        submitButtonLoading: false,
        form: {
          visible: true,
          width: '75%',
          title,
          initialValues,
          onFinish: this.onFinish,
          onValuesChange: e => console.log(e)
        }
      })

    } catch(e) {
      this.props.context.handleException(e);
    }
  }
}