import React from 'react';
import { Cascader } from 'antd';

import _ from 'lodash';

import { withContext } from '@/hoc';
import TABLE from '@/table';

export default
@withContext
class ProductGroupSelect extends React.Component {
  state = {
    loading: false,
    tree: [],
    list: []
  }
  render() {
    const { style, value, onChange, ...props } = this.props;
    const { tree, list } = this.state;

    const obj = _.find(list, { id: value });
    const arr = obj ? _.map(_.compact(`${obj[TABLE.PRODUCT_GROUP.PATH]}${value}`.split('/')), o => parseInt(o, 10)) : []

    return (
      <Cascader value={arr} onChange={e => onChange && onChange(_.last(e))} {..._.omit(props, ['context', 'setContext'])} style={_.defaults(style, {})} options={tree} />
    )
  }

  async refresh() {
    try {
      this.setState({ loading: true })
      const [list] = await this.props.context.request(
        ['product.group.list', {}]
      );

      const buildTree = (PARENT = null) => _(list).filter({ [TABLE.PRODUCT_GROUP.PARENT]: PARENT }).map(o => {
        const children = buildTree(o.id);
        const ret = {
          value: o.id,
          label: o[TABLE.PRODUCT_GROUP.NAME],
        };
        if(_.size(children)) {
          ret.children = children;
        } 
        return ret;
      }).value();
      const tree = buildTree();
      this.setState({ loading: false, tree, list })
    } catch(e) {
      this.setState({ loading: false });
      this.props.context.handleException(e);
    }
  }

  componentDidMount() {
    this.refresh();
  }
}