import _ from 'lodash';

const TABLE = {

  USER: ['zfemkutn', { // 用户
    USERNAME: ['mwhmbhix', t => t.string(255).comment('登录账号')],
    PASSWORD: ['nwlgdopl', t => t.string(255).comment('登录密码')],
  }],

  PRODUCT: ['qatolqxb', { // 数据
    NAME: ['vwyghcko', t => t.string(255).comment('名称')],
    DESCRIPTION: ['zncoambm', t => t.string(255).comment('副标题')],
    IMAGE: ['mrmodgcw', t => t.string(255).comment('主图')],
    PRODUCT_GROUP: ['ibpaqiio', (t, PK) => t.integer().unsigned().notNullable().references(PK.PRODUCT_GROUP).onDelete('CASCADE').comment('产品分组')],
    CONTENT: ['vhrihmdb', t => t.text().comment('内容')],
  }],

  PRODUCT_RELATION: ['cmbqiyja', { // 数据关系
    NAME: ['voipbfep', t => t.string(255).comment('名称')],
    PRODUCT: ['yonjpyrj', (t, PK) => t.integer().unsigned().notNullable().references(PK.PRODUCT).onDelete('CASCADE').comment('关联数据')],
  }],

  PRODUCT_RELATION_ITEM: ['erxcljjr', {
    PRODUCT_RELATION: ['njtxfkfn', (t, PK) => t.integer().unsigned().notNullable().references(PK.PRODUCT_RELATION).onDelete('CASCADE').comment('数据关系')],
    PRODUCT: ['nqghbjcz', (t, PK) => t.integer().unsigned().notNullable().references(PK.PRODUCT).onDelete('CASCADE').comment('关联数据')],
    AMOUNT: ['ygrwedqt', t => t.integer().defaultTo(0).comment('数量')],
  }],

  PRODUCT_GROUP: ['eucffbkc', { // 产品分组
    NAME: ['lrbpfqsx', t => t.string(255).comment('名称')],
    // 排序
    WEIGHT: ['vmyboolo', t => t.integer().defaultTo(0).comment('权重')],
    // 树形结构
    PARENT: ['kghmmouz', (t, PK) => t.integer().unsigned().references(PK.PRODUCT_GROUP).onDelete('CASCADE').comment('父节点')],
    PATH: ['nqyikink', t => t.string(1024).notNullable().comment('ID路径')],
  }],



  // http://tool.c7sky.com/password/

  /*









bobwxiuh
tkfxpdch
xqqwvgkr
xutwovhw
fubskzqk
llxrksjx
jilkadcy
qaxtrpda
pjamolzi
hkkwipxq
mbmauebw
tmxjspog
gzfvcshi
ugyhorwv
crejpsgv
cuscfivq
uclfkogg
kdwmxoah
kaoutpvo
xumsokzd
svnnhgll
atqdzobr
vlrriyiw
habgnlld
uaxbgahm
cdfkshbq
nhpyszkv
vmdwqkcs
epvtcnti
ugiscpzw
vozxmrum
ypauhlkc
izvaxvys
vtwfyzyb
ivrqzvie
jaoxbohz
ustcmaks
kmpmskxd
piizogsv
gmxutmyt
fspuzjhg
fljxiseh
toqmxzfe
vvbdhfjh
mddfllbf
ldxrjjkh
xbmkrzep
oanzoipm
xaxynbza
ozpivlin
lybncbsj
cyacdydw
neqjwiyt
vapkbkln
dcflqysn
ilykztzv
ngxdthlh
nylgmpnz
uhhdngkh
yyyazlmh
yovijcvn
vcmwfnhz
    "", "", "", "", "", 
    "", "", "", "", "", 
    "", "", "", "", "", 
    "", "", "", "", "", 
    "", "", "", "", "", 
    "", "", "", "", "", 
    "", "", "", "", "", 
    "", "", "", "", "", 
    "", "", "", "", "", 
    "", "", "", "", "", 
    "", "", "", "", ""
  */




}

export default _.mapValues(TABLE, o => ({ 0: o[0], ..._.mapValues(o[1], p => p[0]) }))

export { TABLE };