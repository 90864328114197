import React from 'react';
import { Button, Space, Form, Input, Drawer, InputNumber } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import _ from 'lodash';

import { ProductSelect } from '@/component';

import TABLE from '@/table';

class ItemList extends React.Component {
  render() {
    const { value = [], onChange } = this.props;

    return (
      <div>

        {_.map(value, (p, pdx) => (
          <div key={p.id || p._id} style={{display: 'flex', marginBottom: 10}}>
            <ProductSelect
              style={{flex: 1, marginRight: 10}}
              value={{
                value: _.get(p, `${TABLE.PRODUCT_RELATION_ITEM.PRODUCT}.id`),
                label: _.get(p, `${TABLE.PRODUCT_RELATION_ITEM.PRODUCT}.${TABLE.PRODUCT.NAME}`),
              }}
              labelInValue
              onChange={e => {
              onChange && onChange(_.set(_.clone(value), `[${pdx}]${TABLE.PRODUCT_RELATION_ITEM.PRODUCT }`, {
                id: e.value,
                [TABLE.PRODUCT.NAME]: e.label,
              }));
            }} />
            <Input style={{width: 100}} value={p[TABLE.PRODUCT_RELATION_ITEM.AMOUNT]} onChange={({ target: { value: amount }}) => {
              onChange && onChange(_.set(_.clone(value), `[${pdx}]${TABLE.PRODUCT_RELATION_ITEM.AMOUNT }`, amount));
            }} />

          <div onClick={() => {
            const newValue = [
              ..._.slice(value, 0, pdx),
              ..._.slice(value, pdx + 1),
            ]
            onChange && onChange(newValue);
          }} style={{marginLeft: 10, cursor: 'pointer', lineHeight: '32px', display: 'inline-block'}}>
            <CloseOutlined />
          </div>
          </div>
        ))}

        <Button onClick={() => (
          onChange && onChange([...(value || []), { _id: Math.random().toString(36).substr(2, 15) }])
        )}>添加数据</Button>
      </div>
    )
  }
}

export default
class ProductGroupFormButton extends React.Component {
  state = {
    form: {
      visible: false,
    },
    submitButtonLoading: false,
  }
  formRef = React.createRef();
  render() {
    const { okText, cancelText, ...props } = this.props;
    const { form, submitButtonLoading } = this.state;
    return (
      <>
        <Button onClick={this.showForm} {..._.pick(props, ['icon', 'style', 'type'])}>{this.props.children}</Button>
        <Drawer
          className="label-100px"
          destroyOnClose
          onClose={this.hideForm}
          {..._.pick(form, ['width', 'visible', 'title'])}
          footer={
            <div style={{ textAlign: 'right' }}>
              <Space>
                <Button onClick={this.hideForm}>{cancelText || '取消'}</Button>
                <Button loading={submitButtonLoading} onClick={() => this.formRef.current.submit()} type="primary">{okText || '确定'}</Button>
              </Space>
            </div>
          }
        >
          <Form
            ref={this.formRef}
            {..._.pick(form, ['initialValues', 'onFinish', 'onValuesChange'])}
            className="label-100px"
          >
            <Form.Item label="名称" name={TABLE.PRODUCT_RELATION.NAME} rules={[{ required: true, message: '请输入分组名称!' }]}>
              <Input />
            </Form.Item>

            <Form.Item label="关联数据" name="ITEM_LIST" rules={[{ required: true, message: '请输入分组名称!' }]}>
              <ItemList />
            </Form.Item>

          </Form>
        </Drawer>
      </>
    )
  }

  onFinish = formValues => {
    const { onChange, onFinish } = this.props;
    formValues.ITEM_LIST = _.map(formValues.ITEM_LIST, o => ({ 
      ..._.pick(o, ['id', TABLE.PRODUCT_RELATION_ITEM.AMOUNT]),
      [TABLE.PRODUCT_RELATION_ITEM.PRODUCT]: o[TABLE.PRODUCT_RELATION_ITEM.PRODUCT].id,
    }));
    
    if(onFinish) {
      onFinish(formValues, {
        hideForm: this.hideForm,
        submitButtonLoading: this.submitButtonLoading
      })
    } else if(onChange) {
      onChange(formValues);
      this.hideForm()
    }
  }

  hideForm = () => {
    const { form } = this.state;
    this.setState({ form: { ...form, visible: false }})
  }

  submitButtonLoading = submitButtonLoading => this.setState({ submitButtonLoading })
  
  showForm = () => {
    const { value: initialValues = { }, title } = this.props;
    this.formRef.current?.resetFields();
    this.formRef.current?.setFieldsValue(initialValues);
    this.setState({
      submitButtonLoading: false,
      form: {
        visible: true,
        width: '75%',
        title,
        initialValues,
        onFinish: this.onFinish
      }
    })
  }
}