import React from 'react';
import './index.less';

import { EditorState, convertToRaw, convertFromRaw, RichUtils, AtomicBlockUtils } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createImagePlugin from '@draft-js-plugins/image';
import createFocusPlugin from '@draft-js-plugins/focus';

import '@draft-js-plugins/image/lib/plugin.css';

import Trigger from 'rc-trigger';


import _ from 'lodash';
import axios from 'axios';

import { OrderedMap } from 'immutable';

const imagePlugin = createImagePlugin();
const focusPlugin = createFocusPlugin();
const createAtomicPlugin = (type, component) => {
  return {
    blockRendererFn: (block, { getEditorState }) => {
      if(block.getType() === 'atomic') {
        const contentState = getEditorState().getCurrentContent();
        const entityKey = block.getEntityAt(0);
        if (!entityKey) return null;
        const entity = contentState.getEntity(entityKey);
        if(entity && entity.getType() === type) {
          const props = entity.getData();
          return { component, editable: false, props: { ...props, getEditorState } }
        }
      }
    },
    insertEntity: (editorState, props) => {
      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity(type, 'IMMUTABLE', props);
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      return AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
    }
  }
}

const carouselPlugin = setEditorState => createAtomicPlugin('carouse', ({ block, blockProps, getEditorState, ...props }) => (
  <div>carouse: {blockProps.text} <button onClick={() => {

    // const contentState = getEditorState().getCurrentContent();
    // const blockMap = contentState.getBlockMap();

    
    // const newBlocks = blockMap
    //   .toSeq()
    //   .skipUntil((_, k) => k === startKey)
    //   .takeUntil((_, k) => k === endKey)
    //   .concat(Map([[endKey, blockMap.get(endKey)]]))
    //   .map(operation);

    
    // setEditorState()

    console.log(block)
    // block.data = block.data.update('text', '1111')
  }} type="button">新增</button></div>
));
const productPlugin = createAtomicPlugin('product', ({ blockProps }) => (<div>product: {blockProps.text}</div>));
const articlePlugin = createAtomicPlugin('article', ({ blockProps }) => (<div>article: {blockProps.text}</div>));


const CheckButton = ({ value, onChange, disabled, children }) => {
  return (
    <div className={_.compact(['check-button', disabled && 'disabled', value && 'checked']).join(' ')} onClick={e => {
      !disabled && onChange && onChange(!value)
    }} onMouseDown={e => e.preventDefault()}>{children}</div>
  )
}

const HeaderButton = ({ value, onChange }) =>  {

  // | 'paragraph'
  // | 'blockquote'
  // | 'code-block'
  // | 'atomic'
  // | 'section'
  // | 'article';

  const map = OrderedMap([
    ['header-one', '标题1'],
    ['header-two', '标题2'],
    ['header-three', '标题3'],
    ['header-four', '标题4'],
    ['header-five', '标题5'],
    ['header-six', '标题6'],
    ['unstyled', '正文'],
    ['unordered-list-item', '无序列表'],
    ['ordered-list-item', '有序列表'],
  ]);

  const [popupVisible, setPopupVisible] = React.useState(false);
  return (
    <Trigger
      action={['click']}
      popup={<div className="header-button-popup">{map.map((value, key) => (<div key={key} onClick={() => {
        onChange && onChange(key)
        setPopupVisible(false)
      }} onMouseDown={e => e.preventDefault()}>{value}</div>)).toArray()}</div>}
      popupVisible={popupVisible}
      onPopupVisibleChange={setPopupVisible}
      popupAlign={{
        points: ['tl', 'bl'],
        offset: [0, 3]
      }}
    >
      <div className="header-button" onMouseDown={e => e.preventDefault()}>{map.get(value) || value}</div>
    </Trigger>
  )
}

const ClickButton = ({ disabled, children, onClick }) => {
  return (
    <div className={_.compact(['click-button', disabled && 'disabled']).join(' ')} onClick={e => {
      !disabled && onClick && onClick()
    }} onMouseDown={e => e.preventDefault()}>{children}</div>
  )
}



export default
class DraftEditor extends React.Component {
  state = {
    editorState: EditorState.createEmpty(),

    isCollapsed: true,
    BOLD: false,
    ITALIC: false,
    UNDERLINE: false,
    STRIKETHROUGH: false,
    blockType: '',

    plugins: [],
  }
  fileRef = React.createRef()
  render() {
    return (
      <div className="draft-editor">
        <input style={{display: 'none'}} ref={this.fileRef} type="file" />
        <div className="toolbar">
          <CheckButton value={this.state.BOLD} disabled={this.state.isCollapsed} onChange={() => {
            this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, 'BOLD'))
          }}>加粗</CheckButton>

          <CheckButton value={this.state.ITALIC} disabled={this.state.isCollapsed} onChange={() => {
            this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, 'ITALIC'))
          }}>倾斜</CheckButton>

          <CheckButton value={this.state.UNDERLINE} disabled={this.state.isCollapsed} onChange={() => {
            this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, 'UNDERLINE'))
          }}>下划线</CheckButton>

          <CheckButton value={this.state.STRIKETHROUGH} disabled={this.state.isCollapsed} onChange={() => {
            this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, 'STRIKETHROUGH'))
          }}>删除线</CheckButton>

          <HeaderButton onChange={blockType => {
            this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType))
          }} value={this.state.blockType} />

          <ClickButton onClick={() => {
            this.selectFile(async file => {
              const formData = new FormData();
              formData.append('file', file, file.name);
              const [{ url }] = (await axios.post('/upload', formData)).data;
              this.onChange(imagePlugin.addImage(this.state.editorState, url));
            });
          }}>图片</ClickButton>

          <ClickButton onClick={() => {
            this.onChange(carouselPlugin.insertEntity(this.state.editorState, { text: '轮播图' }));
            // this.onChange(carouselPlugin.addCarouse(this.state.editorState, '轮播图'));
          }}>轮播图</ClickButton>

          <ClickButton onClick={() => {
            this.onChange(productPlugin.insertEntity(this.state.editorState, { text: '产品' }));
          }}>产品</ClickButton>

          <ClickButton onClick={() => {
            this.onChange(articlePlugin.insertEntity(this.state.editorState, { text: '文章' }));
          }}>文章</ClickButton>
        </div>
        <Editor
          editorState={this.state.editorState}
          onChange={this.onChange}
          // handlePastedFiles={this.handlePastedFiles}
          // handleDroppedFiles={this.handleDroppedFiles}
          plugins={this.state.plugins}
        />
      </div>
    )
  }

  onChange = editorState => {
    const { onChange } = this.props;
    
    const selection = editorState.getSelection();
    const content = editorState.getCurrentContent();
    
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    // const endKey = selection.getEndKey();
    // const endOffset = selection.getEndOffset();
    const block = content.getBlockForKey(startKey);
    const inlineStyle = block.getInlineStyleAt(startOffset).toJS();
    const blockType = block.getType();
    this.setState({
      isCollapsed: selection.isCollapsed(),
      BOLD: _.includes(inlineStyle, 'BOLD'),
      ITALIC: _.includes(inlineStyle, 'ITALIC'),
      UNDERLINE: _.includes(inlineStyle, 'UNDERLINE'),
      STRIKETHROUGH: _.includes(inlineStyle, 'STRIKETHROUGH'),
      blockType,
    });

    if(onChange) {
      const content = editorState.getCurrentContent();
      if(!content.equals(this.state.editorState.getCurrentContent())) {
        onChange(JSON.stringify(convertToRaw(content)))
      }
    }
    this.setState({ editorState });
  }

  selectFile(callback) {
    if(this.fileRef.current) {
      const fileInput = this.fileRef.current;
      fileInput.setAttribute('accept', 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon');

      const eventHandler = async () => {
        await callback(fileInput.files[0]);
        fileInput.removeEventListener('change', eventHandler);
        fileInput.value = "";
      }
      fileInput.addEventListener('change', eventHandler);
      fileInput.click();
    }
  }

  handlePastedFiles = files => {

    // console.log('handlePastedFiles', e.length)

    // const formData = new FormData();
    // formData.append('file', fileInput.files[0], fileInput.files[0].name);
    // const [{ url }] = (await axios.post('/upload', formData)).data;
  }

  handleDroppedFiles = files => {
    // console.log('handleDroppedFiles', e.length)
  }

  componentDidMount() {
    let editorState = EditorState.createEmpty();
    if(this.props.value) {
      try {
        editorState = EditorState.createWithContent(convertFromRaw(JSON.parseObject(this.props.value)))
      } catch(e) { }
    }
    
    this.setState({ editorState, plugins: [
      focusPlugin, imagePlugin, carouselPlugin(this.onChange), productPlugin, articlePlugin
    ] });
  }
}