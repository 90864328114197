import React from 'react';
import { Button, Space, Form, Input, Drawer, InputNumber } from 'antd';

import _ from 'lodash';

// import { ImageUpload } from '@/component';
import TABLE from '@/table';

export default
class ProductGroupFormButton extends React.Component {
  state = {
    form: {
      visible: false,
    },
    submitButtonLoading: false,
  }
  formRef = React.createRef();
  render() {
    const { okText, cancelText, ...props } = this.props;
    const { form, submitButtonLoading } = this.state;
    return (
      <>
        <Button onClick={this.showForm} {..._.pick(props, ['icon', 'style', 'type'])}>{this.props.children}</Button>
        <Drawer
          className="label-100px"
          destroyOnClose
          onClose={this.hideForm}
          {..._.pick(form, ['width', 'visible', 'title'])}
          footer={
            <div style={{ textAlign: 'right' }}>
              <Space>
                <Button onClick={this.hideForm}>{cancelText || '取消'}</Button>
                <Button loading={submitButtonLoading} onClick={() => this.formRef.current.submit()} type="primary">{okText || '确定'}</Button>
              </Space>
            </div>
          }
        >
          <Form
            ref={this.formRef}
            {..._.pick(form, ['initialValues', 'onFinish', 'onValuesChange'])}
            className="label-100px"
          >
            <Form.Item label="分组名称" name={TABLE.PRODUCT_GROUP.NAME} rules={[{ required: true, message: '请输入分组名称!' }]}>
              <Input />
            </Form.Item>

            <Form.Item label="权重" name={TABLE.PRODUCT_GROUP.WEIGHT}>
              <InputNumber />
            </Form.Item>
          </Form>
        </Drawer>
      </>
    )
  }

  onFinish = formValues => {
    const { onChange, onFinish } = this.props;
    if(onFinish) {
      onFinish(formValues, {
        hideForm: this.hideForm,
        submitButtonLoading: this.submitButtonLoading
      })
    } else if(onChange) {
      onChange(formValues);
      this.hideForm()
    }
  }

  hideForm = () => {
    const { form } = this.state;
    this.setState({ form: { ...form, visible: false }})
  }

  submitButtonLoading = submitButtonLoading => this.setState({ submitButtonLoading })
  
  showForm = () => {
    const { value: initialValues = { }, title } = this.props;
    this.formRef.current?.resetFields();
    this.formRef.current?.setFieldsValue(initialValues);
    this.setState({
      submitButtonLoading: false,
      form: {
        visible: true,
        width: '75%',
        title,
        initialValues,
        onFinish: this.onFinish
      }
    })
  }
}