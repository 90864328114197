import React from 'react';
import { Form, Input, Button } from 'antd';

import { withContext } from '@/hoc';
import TABLE from '@/table';

export default
@withContext
class extends React.Component {
  state = {

  };
  
  render() {
    return (
      <div>
        <Form
          style={{paddingTop: 20, width: 300}}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={this.onFinish}
        >
          <Form.Item label="登录账号" name={TABLE.USER.USERNAME} rules={[{ required: true, message: '请输入登录账号!' }]}>
            <Input />
          </Form.Item>

          <Form.Item label="登录密码" name={TABLE.USER.PASSWORD} rules={[{ required: true, message: '请输入登录密码!' }]}>
            <Input.Password />
          </Form.Item>
          
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">登录</Button>
          </Form.Item>
        </Form>
      </div>
    )
  }

  onFinish = async values => {
    try {
      const [{ user, token, refresh_token }] = await this.props.context.request(
        ['account.login', values]
      );

      localStorage.setItem('token', token);
      localStorage.setItem('refresh_token', refresh_token);

      this.props.setContext({ user, token });
      this.props.context.success('登录成功');
    } catch(e) {
      this.props.context.handleException(e);
    }
  }
}