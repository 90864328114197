import React from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import App from './App';
import reportWebVitals from './reportWebVitals';

import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/zh-cn';

moment.locale('zh-cn');

console.log(`
MMMMMMMMMMMMMM    MMMMMM  MM    MMMMMM      MMMMMMMMMMMMMM
MM          MM        MMMM        MM    MM  MM          MM
MM  MMMMMM  MM    MMMMMM    MM  MM  MMMMMM  MM  MMMMMM  MM
MM  MMMMMM  MM  MM  MMMM  MM      MM  MMMM  MM  MMMMMM  MM
MM  MMMMMM  MM  MM  MM  MM      MMMMMMMM    MM  MMMMMM  MM
MM          MM    MMMM    MM    MMMMMM  MM  MM          MM
MMMMMMMMMMMMMM  MM  MM  MM  MM  MM  MM  MM  MMMMMMMMMMMMMM
                  MM    MM      MM      MM                
MMMM      MMMMMM  MMMM  MMMMMMMMMMMM  MM        MMMM      
  MM  MMMMMM  MMMM  MM        MM  MM  MM      MMMM  MMMM  
MMMMMM  MM  MMMM  MM  MMMMMMMMMMMM  MM  MM  MMMMMM        
                MM  MMMM            MMMMMM      MMMM      
MM    MM  MMMMMM  MMMM          MM  MM  MM  MMMM        MM
MM        MM    MM  MM  MM    MMMM    MMMM  MMMMMM    MMMM
  MM  MM  MMMM  MM          MMMMMM  MM  MMMMMM    MMMM    
MM  MMMMMMMM            MM    MMMM    MMMMMMMMMM    MM  MM
  MMMMMM    MM      MM  MMMMMMMM  MMMM            MMMM    
MM  MM          MM  MM    MM  MMMM    MMMMMMMM  MM  MMMMMM
MMMM  MM  MMMMMMMM      MM    MMMM  MMMMMMMMMMMM  MM    MM
MM  MMMMMM    MMMMMM  MM    MM  MM    MMMM      MM        
MM  MMMMMMMMMM    MM  MMMMMMMMMM  MMMMMMMMMMMMMMMM  MMMMMM
                MM  MM  MM  MMMM  MM    MM      MMMM      
MMMMMMMMMMMMMM  MMMMMM  MMMM  MM    MMMMMM  MM  MMMMMM    
MM          MM  MM  MM  MM      MM  MM  MM      MM    MM  
MM  MMMMMM  MM      MM        MM    MMMMMMMMMMMMMMMM      
MM  MMMMMM  MM        MMMM    MM  MM  MM  MM  MM  MMMM  MM
MM  MMMMMM  MM    MM    MM  MMMM    MM  MMMMMMMMMMMMMMMM  
MM          MM  MM  MMMMMM  MMMM      MM    MMMMMMMMMM  MM
MMMMMMMMMMMMMM  MMMM    MMMMMMMMMMMMMMMM        MMMMMM    
`)

JSON.parseArray = str => {
  if(!str) return [];
  let array = [];
  try {
    array = JSON.parse(str);
    array = _.isArray(array) ? array : [];
  } catch(e) {};
  return array;
}

JSON.parseObject = str => {
  let object = {};
  try {
    object = JSON.parse(str);
    object = _.isPlainObject(object) ? object : {};
  } catch(e) {};
  return object;
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
