import React from 'react';
import { Input, Pagination, Button } from 'antd';
import { Link } from 'react-router-dom';
import { EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import _ from 'lodash';
// import moment from 'moment';

import { withContext } from '@/hoc';
import TABLE from '@/table';

import { ProductFormButton, ProductGroupFormButton } from '@/component';

export default
@withContext
class extends React.Component {
  state = {
    loading: false,
    object: null,

    list: [],
    seaching: false,
    total: 0,
    current: 1,
    pageSize: 10,

    query: {},

    groupList: [],
    groupTree: [],
  };
  
  render() {
    const { query, list, loading, object } = this.state;
    const { user } = this.props.context;

    if(!object) {
      return null;
    }

    return (
      <div style={{padding: 10}}>

        <div style={{fontSize: 18, fontWeight: 'bold'}}>
          {object[TABLE.PRODUCT_GROUP.NAME]}
          {!!user && (
            <span style={{marginLeft: 10}}>
              <ProductGroupFormButton icon={<EditOutlined />} type="link" value={object} onFinish={this.onUpdate(object)} title="编辑数据分组" okText="保存" />
              <Button icon={<DeleteOutlined />} type="link" onClick={() => this.onRemove(object)} danger />

              <ProductGroupFormButton icon={<PlusOutlined />} type="link" onFinish={this.onCreate({ [TABLE.PRODUCT_GROUP.PARENT]: object.id })} title="新增数据分组" okText="新增">分组</ProductGroupFormButton>
              <ProductFormButton icon={<PlusOutlined />} type="link" value={{ [TABLE.PRODUCT.PRODUCT_GROUP]: object.id }} onFinish={this.onCreateProduct({ [TABLE.PRODUCT.PRODUCT_GROUP]: object.id })} title="新增数据" okText="新增">数据</ProductFormButton>
            </span>
          )}
        </div>

        
        <div>{_(object[TABLE.PRODUCT_GROUP.PATH]).map((o, odx) => [
          !!odx && <div key={`sep_${o.id}`} style={{display: 'inline-block', margin: '0 10px', color: 'gray'}}> / </div>,
          <Link key={`link_${o.id}`} style={{color: 'gray'}} to={`/group/${o.id}`}>{o[TABLE.PRODUCT_GROUP.NAME]}</Link>,
        ]).flatten().value()}</div>

        {!!_.size(object.children) && (
          <div style={{marginTop: 10, border: '1px solid lightgray', padding: 10}}>
            {_(object.children).filter(p => _.size(p.children)).map(p => (
              <div key={p.id}>
                <Link style={{marginRight: 10}} to={`/group/${p.id}`}>{p.title}</Link>
                {_.map(p.children, q => (
                  <Link key={q.id} to={`/group/${q.id}`} style={{display: 'inline-block', marginRight: 10}}>{q.title}</Link>
                ))}
              </div>
            )).value()}

            <div>
              {_(object.children).filter(p => !_.size(p.children)).map(p => (
                <Link key={p.id} to={`/group/${p.id}`} style={{marginRight: 10, display: 'inline-block'}}>{p.title}</Link>
              )).value()}
            </div>
          </div>
        )}

        <Input allowClear value={this.state.query[TABLE.PRODUCT.NAME]} onChange={({ target: { value }}) => this.setState({ current: 1, query: { ...query, [TABLE.PRODUCT.NAME]: value}}, this.refresh)} placeholder="请输入要搜索的数据" style={{marginTop: 10, width: '100%' }} />
        {_.size(list) ? _.map(list, o => (
          <div key={o.id} style={{marginTop: 10, padding: 10, border: '1px solid lightgray'}}>
            <Link style={{display: 'block'}} key={o.id} to={`/product/${o.id}`}>
              {o[TABLE.PRODUCT.NAME]}
            </Link>
            <div style={{fontSize: 12, color: 'gray'}}>
              {_.map(o[TABLE.PRODUCT.PRODUCT_GROUP], TABLE.PRODUCT_GROUP.NAME).join(' / ')}
            </div>
          </div>
        )) : (
          <div style={{ marginTop: 10, padding: 20, textAlign: 'center', border: '1px solid lightgray', color: 'gray'}}>无数据</div>
        )}

        <Pagination hideOnSinglePage simple style={{ marginTop: 10, textAlign: 'center' }} onChange={async (current, pageSize) => this.setState({ current, pageSize }, this.refresh)} showTotal={total => `共 ${total} 条记录`} {..._.pick(this.state, ['total', 'current', 'pageSize'])} />
        
      </div>
    )
  }

  async componentDidUpdate(prevProps) {
    const keys = ['match'];
    if(!_.isEqual(_.pick(this.props, keys), _.pick(prevProps, keys))) {
      await this.refreshGroup();
      await this.refresh();
    }
  }

  onCreate = hideValue => async (value, { hideForm, submitButtonLoading }) => {
    try {
      submitButtonLoading(true)
      await this.props.context.request(
        ['product.group.create', _.assign({}, value, hideValue)]
      );
      this.props.context.success('创建成功');
      hideForm();
      await this.refreshGroup();
    } catch(e) {
      this.props.context.handleException(e);
    }
  }

  onUpdate = ({ id }) => async (value, { hideForm, submitButtonLoading }) => {
    try {
      submitButtonLoading(true)
      await this.props.context.request(
        ['product.group.update', { id }, value]
      );
      this.props.context.success('保存成功');
      hideForm();
      await this.refreshGroup();
      await this.refresh();
    } catch(e) {
      this.props.context.handleException(e);
    }
  }

  onRemove = async ({ id, ...value }) => {
    try {
      await this.props.context.confirm(`确认删除: ${value[TABLE.PRODUCT_GROUP.NAME]}`);
    
      await this.props.context.request(
        ['product.group.remove', id],
      );
      this.props.context.success('删除成功');
      
      const pid = _.get(_.last(value[TABLE.PRODUCT_GROUP.PATH]), 'id');
      this.props.history.replace(
        pid ? `/group/${pid}` : '/'
      )

    } catch(e) {
      this.props.context.handleException(e);
    }
  }

  onCreateProduct = hideValue => async (value, { hideForm, submitButtonLoading }) => {
    try {
      submitButtonLoading(true)
      await this.props.context.request(
        ['product.create', _.assign({}, value, hideValue)]
      );
      this.props.context.success('创建成功');
      hideForm();
      this.refresh();
    } catch(e) {
      this.props.context.handleException(e);
    }
  }

  refreshGroup = async () => {
    const { id } = this.props.match.params;
    try {
      this.setState({ loading: true })

      const [list] = await this.props.context.request(
        ['product.group.list'],
      );

      const buildTree = (PARENT = null, PARENT_FULLNAME) => {
        const children = _.filter(list, { [TABLE.PRODUCT_GROUP.PARENT]: PARENT });
        return _.map(children, o => {
          const FULLNAME = PARENT_FULLNAME ? `${PARENT_FULLNAME} > ${o[TABLE.PRODUCT_GROUP.NAME]}` : o[TABLE.PRODUCT_GROUP.NAME];
          o.FULLNAME = FULLNAME
          return {
            key: o.id,
            title: o[TABLE.PRODUCT_GROUP.NAME],
            ...o,
            children: buildTree(o.id, FULLNAME)
          }
        })
      }

      const object = _.find(list, o => o.id == id)
      if(object) {
        object[TABLE.PRODUCT_GROUP.PATH] = _.map(_.compact(object[TABLE.PRODUCT_GROUP.PATH].split('/')), o => _.find(list, p => p.id == o));
        object.children = buildTree(object.id);
      }

      
      this.setState({ loading: false, object })
    } catch(e) {
      this.setState({ loading: false });
      this.props.context.handleException(e);
    }
  }

  refresh = async () => {
    const { id } = this.props.match.params;
    try {
      const { query, current, pageSize } = this.state;
      const offset = (current - 1) * pageSize;
      const limit = pageSize;
      this.setState({ seaching: true, list: [], total: 0 })

      query[TABLE.PRODUCT.PRODUCT_GROUP] = id
      const [list, total] = await this.props.context.request(
        ['product.list', query, { offset, limit }],
        ['product.count', query]
      );

      this.setState({ seaching: false, list, total })
    } catch(e) {
      this.setState({ seaching: false });
      this.props.context.handleException(e);
    }
  }
  
  async componentDidMount() {
    await this.refreshGroup();
    await this.refresh();
  }
}