import React from 'react';
import { Table } from 'antd';

import './popup.less';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: 150,
  },
  {
    title: 'Age',
    dataIndex: 'age',
    width: 150,
  },
  {
    title: 'Address',
    dataIndex: 'address',
  },
];

const data = [];
for (let i = 0; i < 100; i++) {
  data.push({
    key: i,
    name: `Edward King ${i}`,
    age: 32,
    address: `London, Park Lane no. ${i}`,
  });
}

export default class extends React.Component {
  state = {
    visible: false,
    clientHeight: 0,
    clientWidth: 0,
  }
  render() {
    return (
      <div>
        <div style={{cursor: 'pointer', border: '1px solid red', margin: 10, padding: '5px 10px', display: 'inline-block'}} onClick={() => this.setState({ visible: true })}>点我</div>
      
        {this.state.visible && (
          <div style={{position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{backgroundColor: 'white', width: Math.max(this.state.clientHeight, this.state.clientWidth) / 2, maxHeight: 500,}}>
              <div style={{position: 'relative', padding: 10}}>
                弹框标题
                <div onClick={() => this.setState({ visible: false })} style={{position: 'absolute', top: 10, right: 10, cursor: 'pointer'}}>关闭</div>
              </div>
              <div style={{padding: 10}}>
                <Table className="popup-table" columns={columns} dataSource={data} pagination={{ pageSize: 30 }} scroll={{ y: 240 }} />
              </div>
              <div style={{cursor: 'pointer', border: '1px solid gray', padding: 10, margin: 10, textAlign: 'center'}} onClick={() => this.setState({ visible: false})}>确定</div>
            </div>
          </div>
        )}
      </div>
    )
  }

  componentDidMount() {
    this.setState({
      clientHeight: document.body.clientHeight,
      clientWidth: document.body.clientWidth,
    });
    window.onresize = () => {
      this.setState({
        clientHeight: document.body.clientHeight,
        clientWidth: document.body.clientWidth,
      });
    };
  }
}