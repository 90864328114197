import React from 'react';
import { Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

export default
class ImpageUpload extends React.Component {
  state = {
    loading: false,
  };

  render() {
    const { loading } = this.state;
    const { value, className, placeholder } = this.props;

    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        {!!placeholder && (
          <div style={{ marginTop: 8 }}>{placeholder}</div>
        )}
      </div>
    );

    return (
      <Upload
        name="avatar"
        listType="picture-card"
        className={className}
        showUploadList={false}
        action="/upload?format=ant"
        onChange={this.handleChange}
      >
        {value ? <img src={value} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
      </Upload>
    )
  }

  handleChange = ({ file }) => {
    const { onChange } = this.props;
    if(file.status === 'uploading') {
      this.setState({ loading: true })
    } else if(file.response) {
      onChange && onChange(file.response[0].url)
      this.setState({ loading: false })
    }
  }
}