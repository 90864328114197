import React from 'react';
import Trigger from 'rc-trigger';

import { TwitterPicker } from 'react-color';

export default class ColorInput extends React.Component {
  render() {
    const { value = '#000000', onChange } = this.props;
    return (
      <Trigger
        action={['hover']}
        popupAlign={{
          points: ['tr', 'br'],
          offset: [5, 10]
        }}
        popup={<TwitterPicker triangle="top-right" color={value} onChange={({ hex }) => onChange && onChange(hex)} />}
        >
        <div style={{cursor: 'pointer', verticalAlign: 'top', display: 'inline-block', width: 30, height: 30, borderRadius: 4, backgroundColor: value}} />
      </Trigger>
    )
  }
}