import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import _ from 'lodash';
// import moment from 'moment';

import { withContext } from '@/hoc';
import TABLE from '@/table';

import { ProductFormButton, ProductRelationFormButton } from '@/component';

const formatAmount = number => {
  if(number >= 1E8) return `${number / 1E8}E`;
  if(number >= 1E4) return `${number / 1E4}W`;
  return number
}
export default
@withContext
class extends React.Component {
  state = {
    loading: false,
    object: null,
  };

  
  render() {
    const { object } = this.state;
    const { id } = this.props.match.params;
    const { user } = this.props.context;

    if(!object) {
      return null;
    }

    const content = JSON.parseObject(object[TABLE.PRODUCT.CONTENT])
    
    return (
      <div style={{padding: 10}}>
        <div style={{fontSize: 18, fontWeight: 'bold'}}>
          {object[TABLE.PRODUCT.NAME]}
          {!!user && (
            <span style={{marginLeft: 10}}>
              <ProductFormButton icon={<EditOutlined />} type="link" value={object} onFinish={this.onUpdate(object)} title="编辑数据分组" okText="保存" />
              <Button icon={<DeleteOutlined />} type="link" onClick={() => this.onRemove(object)} danger />
              <ProductRelationFormButton icon={<PlusOutlined />} type="link" onFinish={this.onCreateRelation({ [TABLE.PRODUCT_RELATION.PRODUCT]: object.id })} title="新增数据关系" okText="新增" >数据关系</ProductRelationFormButton>
            </span>
          )}
        </div>

        <div>{_(object[TABLE.PRODUCT.PRODUCT_GROUP]).map((o, odx) => [
          !!odx && <div key={`sep_${o.id}`} style={{display: 'inline-block', margin: '0 10px', color: 'gray'}}> / </div>,
          <Link key={`link_${o.id}`} style={{color: 'gray'}} to={`/group/${o.id}`}>{o[TABLE.PRODUCT_GROUP.NAME]}</Link>,
        ]).flatten().value()}</div>

        {(_.size(content.blocks) > 1 || !!_.get(content.blocks, '[0].text')) && (
          <div style={{marginTop: 10, padding: 10, border: '1px solid #1890ff', position: 'relative'}}>
            {_.map(content.blocks, o => (
              <div key={o.key} style={{whiteSpace: 'pre-wrap'}} dangerouslySetInnerHTML={{ __html: o.text }} />
            ))}
          </div>
        )}
        
        

        {_.map(object['PRODUCT_RELATION'], o => (
          <div key={o.id} style={{marginTop: 10, padding: 10, border: '1px solid #1890ff', position: 'relative'}}>
            <div>{o[TABLE.PRODUCT_RELATION.NAME]}</div>
            <div style={{}}>{_.map(o['ITEM_LIST'], p => (
              <div key={p.id} style={{display: 'inline-block'}}><Link to={`/product/${p[TABLE.PRODUCT_RELATION_ITEM.PRODUCT].id}`}>{p[TABLE.PRODUCT_RELATION_ITEM.PRODUCT][TABLE.PRODUCT.NAME]}</Link>{p[TABLE.PRODUCT_RELATION_ITEM.AMOUNT] > 0 && (<><span style={{padding: '0 2px'}}>x</span>{formatAmount(p[TABLE.PRODUCT_RELATION_ITEM.AMOUNT])}</>)}<span style={{marginRight: 5}}>&nbsp;</span></div>
            ))}</div>

            {!!user && (
              <div style={{position: 'absolute', top: 5, right: 5}}>
                <ProductRelationFormButton icon={<EditOutlined />} type="link" value={o} onFinish={this.onUpdateRelation(o)} title="编辑数据关系" okText="保存" />
                <Button icon={<DeleteOutlined />} type="link" onClick={() => this.onRemoveRelation(o)} danger />
              </div>
            )}
          </div>
        ))}

        {_.map(object['PRODUCT_RELATION_FROM'], o => (
          <div key={o.id} style={{marginTop: 10, padding: 10, border: '1px solid lightgray'}}>
            <div><Link to={`/product/${o[TABLE.PRODUCT_RELATION.PRODUCT].id}`} style={{marginRight: 5}}>{o[TABLE.PRODUCT_RELATION.PRODUCT][TABLE.PRODUCT.NAME]}</Link>{o[TABLE.PRODUCT_RELATION.NAME]}</div>
            <div style={{}}>{_.map(o['ITEM_LIST'], p => (
              <div key={p.id} style={{display: 'inline-block'}}> { p[TABLE.PRODUCT_RELATION_ITEM.PRODUCT].id != id ? <Link to={`/product/${p[TABLE.PRODUCT_RELATION_ITEM.PRODUCT].id}`}>{p[TABLE.PRODUCT_RELATION_ITEM.PRODUCT][TABLE.PRODUCT.NAME]}</Link> : <span style={{color: '#ff4d4f'}}>{p[TABLE.PRODUCT_RELATION_ITEM.PRODUCT][TABLE.PRODUCT.NAME]}</span>}{p[TABLE.PRODUCT_RELATION_ITEM.AMOUNT] > 0 && (<><span style={{padding: '0 2px'}}>x</span>{formatAmount(p[TABLE.PRODUCT_RELATION_ITEM.AMOUNT])}</>)}<span style={{marginRight: 5}}>&nbsp;</span></div>
            ))}</div>
          </div>
        ))}
      </div>
    )
  }

  componentDidUpdate(prevProps) {
    const keys = ['match'];
    if(!_.isEqual(_.pick(this.props, keys), _.pick(prevProps, keys))) {
      this.refresh()
    }
  }

  onUpdate = ({ id }) => async (value, { hideForm, submitButtonLoading }) => {
    try {
      submitButtonLoading(true)
      await this.props.context.request(
        ['product.update', { id }, value]
      );
      this.props.context.success('保存成功');
      hideForm();
      await this.refresh();
    } catch(e) {
      this.props.context.handleException(e);
    }
  }

  onRemove = async ({ id, ...value }) => {

    const gid = _.get(_.last(value[TABLE.PRODUCT.PRODUCT_GROUP]), 'id');
    try {
      await this.props.context.confirm(`确认删除: ${value[TABLE.PRODUCT.NAME]}`);
    
      await this.props.context.request(
        ['product.remove', id],
      );
      this.props.context.success('删除成功');
      this.props.history.replace(`/group/${gid}`)

    } catch(e) {
      this.props.context.handleException(e);
    }
  }

  onCreateRelation = hideValue => async (value, { hideForm, submitButtonLoading }) => {
    try {
      submitButtonLoading(true)
      await this.props.context.request(
        ['product.relation.create', _.assign({}, value, hideValue)]
      );
      this.props.context.success('创建成功');
      hideForm();
      await this.refresh();
    } catch(e) {
      this.props.context.handleException(e);
    }
  }

  onUpdateRelation = ({ id }) => async (value, { hideForm, submitButtonLoading }) => {

    try {
      submitButtonLoading(true)
      await this.props.context.request(
        ['product.relation.update', { id }, value]
      );
      this.props.context.success('保存成功');
      hideForm();
      await this.refresh();
    } catch(e) {
      this.props.context.handleException(e);
    }
  }

  onRemoveRelation = async ({ id, ...value }) => {
    try {
      await this.props.context.confirm(`确认删除: ${value[TABLE.PRODUCT_RELATION.NAME]}`);
      await this.props.context.request(
        ['product.relation.remove', id],
      );
      this.props.context.success('删除成功');
      await this.refresh();
    } catch(e) {
      this.props.context.handleException(e);
    }
  }

  refresh = async () => {
    const { id } = this.props.match.params;
    try {
      this.setState({ loading: true })
      const [object] = await this.props.context.request(
        ['product.find', { id }]
      );
      this.setState({ loading: false, object });
      
    }catch(e) {
      this.setState({ loading: false });
      this.props.context.handleException(e);
    }
  }

  async componentDidMount() {
    await this.refresh();
  }
}