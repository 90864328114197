import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';

import * as screen from '@/screen';
import * as context from '@/context';
import { withAntd, withRedux, withContext } from '@/hoc';

import TABLE from '@/table';

export default
@withAntd
@withRedux
@withContext
class extends React.Component {
  state = {
    initialized: false,
  }
  render() {
    if(!this.state.initialized) return null;
    const { user } = this.props.context;
    return (
      <Router>
        <Switch>
          {!user && (<Route exact path='/login' component={screen.Login} />)}
          <Route path='/popup' component={screen.Popup} />
          <Route path='/' render={props => (
            <screen.Layout {...props}>
              <Switch>
                <Route exact path='/product/:id' component={screen.Product} />
                <Route exact path='/group/:id' component={screen.ProductGroup} />
                <Route exact path='/' component={screen.Home} />
                <Redirect to='/' />
              </Switch>
            </screen.Layout>
          )} />

          
          
        </Switch>
      </Router>
    )
  }

  async componentDidMount() {
    this.props.setContext(context);

    if(localStorage.getItem('token')) {
      try {
        const [{ user }] = await context.request(
          ['account.user']
        );

        console.log(user[TABLE.USER.USERNAME])
        this.props.setContext({ user })
      } catch(e) {
        const message = e.response ? e.response.data : e.message;
        console.log(message)
      }
    }
    this.setState({ initialized: true })
  }
}
