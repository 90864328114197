// import _ from 'lodash';
import { Modal } from 'antd';

const confirm = msg => new Promise((resolve, reject) => Modal.confirm({
  title: '操作确认',
  content: msg,
  onOk: () => resolve(),
  onCancel: () => reject('cancel'),
}))

export default confirm;