import React from 'react';
import { Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import _ from 'lodash';

export default
class ImpageListUpload extends React.Component {
  state = {
    fileList: []
  }
  render() {

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>上传图片</div>
      </div>
    );

    return (
      <Upload
        action="/upload?format=ant"
        listType="picture-card"
        fileList={this.state.fileList}
        onPreview={this.handlePreview}
        onChange={this.handleChange}
      >
        {this.state.fileList.length >= 8 ? null : uploadButton}
      </Upload>
    )
  }

  handleChange = ({ file, fileList }) => {
    const { onChange } = this.props;
    if(file.status === 'done' && onChange) {
      const newValue = _.map(fileList, o => o.url || o.response[0].url)
      onChange(newValue.join(','))
      this.setState({ fileList })
    } else {
      this.setState({ fileList })
    }
  }

  componentDidUpdate(prevProps) {
    const keys = ['value'];
    if(!_.isEqual(_.pick(this.props, keys), _.pick(prevProps, keys))) {
      const value = _.map(this.state.fileList, o => o.url || o.response[0].url).join(',');
      if(!_.isEqual(value, this.props.value)) {
        const fileList = _.map(this.props.value ? _.split(this.props.value, ',') : [], (url, index) => ({
          uid: `init-${index}`,
          name: _.last(url.split('/')),
          url
        }));

        this.setState({ fileList })
      }
    }
    
  }

  componentDidMount() {
    this.setState({ fileList: _.map(this.props.value ? _.split(this.props.value, ',') : [], (url, index) => ({
      uid: `init-${index}`,
      name: _.last(url.split('/')),
      url
    }))})
  }
}