import React from 'react';
import { Select, Spin, Button } from 'antd';

import _ from 'lodash';

import { withContext } from '@/hoc';
import TABLE from '@/table';

export default
@withContext
class ProductSelect extends React.Component {
  state = {
    loading: false,
    list: []
  }
  render() {
    const { style, value, onChange, ...props } = this.props;
    const { loading, list } = this.state;

    // const obj = _.find(list, { id: value });
    // const arr = obj ? _.map(_.compact(`${obj[TABLE.PRODUCT_GROUP.PATH]}${value}`.split('/')), o => parseInt(o, 10)) : []

    return (
      <Select
        value={value}
        filterOption={false}
        onChange={e => onChange && onChange(e)}
        onSearch={this.onSearch}
        onDropdownVisibleChange={open => open && this.setState({ list: [] })}
        options={list}
        notFoundContent={loading ? <Spin size="small" /> : null}
        showSearch
        showArrow={false}
        style={_.defaults(style, {})}
        {..._.omit(props, ['context', 'setContext'])}
      />
    )
  }

  onSearch = async value => {
    if(!value) {
      this.setState({ list: [] })
      return;
    }

    try {
      this.setState({ loading: true })
      const [list] = await this.props.context.request(
        ['product.list', { [TABLE.PRODUCT.NAME]: value }, { offset: 0, limit: 10 }]
      );
      this.setState({ loading: false, list: _.map(list, o => ({ ...o, value: o.id, label: o[TABLE.PRODUCT.NAME]})) })
    } catch(e) {
      this.setState({ loading: false });
      this.props.context.handleException(e);
    }
  }

  componentDidMount() {
    const { value, label } = this.props;
    console.log(value, label)
    if(value) {
      this.setState({
        list: [{ value, label }]
      })
    }
    
  }
}