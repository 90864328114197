import React from 'react';
import { Tree, Button, Space } from 'antd';

import _ from 'lodash';

import { withContext } from '@/hoc';
import TABLE from '@/table';

import { ProductGroupFormButton } from '..';

export default
@withContext
class ProductGroupTree extends React.Component {
  state = {
    tree: [],
    list: [],
    expandedKeys: null,
    selectedKey: null,
  };

  render() {
    const { onChange, editable } = this.props;
    const { selectedKey, list } = this.state;
    const selected = selectedKey ? _.find(list, { id: selectedKey }) : null;
    return (
      <>
        {!!editable && (
          <Space style={{marginBottom: 10}}>
            <ProductGroupFormButton onFinish={this.onCreate} title="新增数据分组" okText="新增">新增</ProductGroupFormButton>
            {!!selectedKey && (
              <>
                <ProductGroupFormButton value={selected} onFinish={this.onUpdate(selected)} title="编辑数据分组" okText="保存">编辑</ProductGroupFormButton>
                <Button onClick={() => this.onRemove(selected)} danger>删除</Button>
              </>
            )}
          </Space>
        )}
        <Tree
          blockNode
          showLine={{ showLeafIcon: false }}
          onSelect={async ([selectedKey]) => {
            this.setState({ selectedKey });
            onChange && onChange(selectedKey);
          }}
          selectedKeys={[selectedKey]}
          onExpand={expandedKeys => this.setState({ expandedKeys })}
          expandedKeys={this.state.expandedKeys}
          titleRender={o => o[TABLE.PRODUCT_GROUP.NAME]}
          treeData={this.state.tree}
        />
      </>
    )
  }

  onCreate = async (value, { hideForm }) => {
    const { selectedKey } = this.state;
    try {
      value[TABLE.PRODUCT_GROUP.PARENT] = selectedKey;
      await this.props.context.request(
        ['product.group.create', value]
      );
      this.props.context.success('创建成功');
      hideForm();
      this.refresh();
    } catch(e) {
      this.props.context.handleException(e);
    }
  }

  onUpdate = ({ id }) => async (value, { hideForm }) => {
    try {
      await this.props.context.request(
        ['product.group.update', { id }, value]
      );
      this.props.context.success('保存成功');
      hideForm();
      this.refresh();
    } catch(e) {
      this.props.context.handleException(e);
    }
  }

  onRemove = async ({ id, ...value }) => {
    try {
      await this.props.context.confirm(`确认删除: ${value[TABLE.PRODUCT_GROUP.NAME]}`);
      await this.props.context.request(
        ['product.group.remove', id],
      );
      this.props.context.success('删除成功');
      this.refresh();
    } catch(e) {
      this.props.context.handleException(e);
    }
  }

  refresh = async () => {
    try {
      const { onChange } = this.props;
      this.setState({ loading: true })

      const [list] = await this.props.context.request(
        ['product.group.list'],
      );

      const buildTree = (PARENT = null, PARENT_FULLNAME) => {
        const children = _.filter(list, { [TABLE.PRODUCT_GROUP.PARENT]: PARENT });
        return _.map(children, o => {
          const FULLNAME = PARENT_FULLNAME ? `${PARENT_FULLNAME} > ${o[TABLE.PRODUCT_GROUP.NAME]}` : o[TABLE.PRODUCT_GROUP.NAME];
          o.FULLNAME = FULLNAME
          return {
            key: o.id,
            title: o[TABLE.PRODUCT_GROUP.NAME],
            ...o,
            children: buildTree(o.id, FULLNAME)
          }
        })
      }

      const tree = buildTree();
      const state = { loading: false, list, tree }
      if(this.state.selectedKey && !_.find(list, { id: this.state.selectedKey })) {
        state.selectedKey = null;
        onChange && onChange(state.selectedKey)
      }
      if(!_.size(this.state.expandedKeys)) {
        state.expandedKeys = _.map(list, 'id');
      }
      this.setState(state)
    } catch(e) {
      this.setState({ loading: false });
      this.props.context.handleException(e);
    }
  }
  async componentDidMount() {
    await this.refresh();
  }
}