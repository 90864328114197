import _ from 'lodash';
import axios from 'axios';

export const request = async (...args) => {
  try {
    const headers = {};
    const token = localStorage.getItem('token');
    if(token) {
      headers.Authorization = token;
    }
    const res = await axios.post('/api/java', args, { headers });
    return res.data;
  } catch(e) {
    if(e.response) {
      if(e.response.data === 'ERROR_TOKEN_02') {
        const refresh_token = localStorage.getItem('refresh_token');
        if(refresh_token) {
          const [{ token }] = await request(
            ['account.refreshToken', { refresh_token }]
          );
          localStorage.setItem('token', token);
          return request(...args);
        }
      }
      throw new Error(e.response.data)
    };
    throw e;
  }
}

export const beginRequest = () => {
  const array = [];
  return {
    push: (req, callback) => array.push({ req, callback }),
    endRequest: async () => {
      const res = await request(..._.map(array, 'req'));
      return _.defaults({}, ..._.map(array, (o, index) => o.callback ? o.callback(res[index]) : {} ))
    },
  }
}