import React from 'react';
import { Input } from 'antd';

import _ from 'lodash';
// import moment from 'moment';

import { withContext } from '@/hoc';
import TABLE from '@/table';
import { Link } from 'react-router-dom';

export default
@withContext
class extends React.Component {
  state = {
    loading: false,
    list: [],
    tree: [],
  };

  
  render() {
    const { tree } = this.state;

    return (
      <div style={{padding: 10}}>

        <div style={{fontSize: 18, fontWeight: 'bold'}}>请选择数据分类</div>
        {_.map(tree, o => (
          <div key={o.id} style={{border: '1px solid lightgray', padding: 10, marginTop: 10}}>
            <Link to={`/group/${o.id}`}>{o.title}</Link>

            <div>
              {_(o.children).filter(p => _.size(p.children)).map(p => (
                <div key={p.id}>
                  <Link style={{marginRight: 10}} to={`/group/${p.id}`}>{p.title}</Link>
                  {_.map(p.children, q => (
                    <Link key={q.id} to={`/group/${q.id}`} style={{display: 'inline-block', marginRight: 10}}>{q.title}</Link>
                  ))}
                </div>
              )).value()}

              <div>
              {_(o.children).filter(p => !_.size(p.children)).map(p => (
                <Link key={p.id} to={`/group/${p.id}`} style={{marginRight: 10}}>{p.title}</Link>
              )).value()}
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }

  refresh = async () => {
    try {
      this.setState({ loading: true })

      const [list] = await this.props.context.request(
        ['product.group.list'],
      );

      const buildTree = (PARENT = null, PARENT_FULLNAME) => {
        const children = _.filter(list, { [TABLE.PRODUCT_GROUP.PARENT]: PARENT });
        return _.map(children, o => {
          const FULLNAME = PARENT_FULLNAME ? `${PARENT_FULLNAME} > ${o[TABLE.PRODUCT_GROUP.NAME]}` : o[TABLE.PRODUCT_GROUP.NAME];
          o.FULLNAME = FULLNAME
          return {
            key: o.id,
            title: o[TABLE.PRODUCT_GROUP.NAME],
            ...o,
            children: buildTree(o.id, FULLNAME)
          }
        })
      }

      const tree = buildTree();
      this.setState({ loading: false, list, tree })
    } catch(e) {
      this.setState({ loading: false });
      this.props.context.handleException(e);
    }
  }

  componentDidMount() {
    this.refresh();
  }
}