import React from 'react';
import { Table, Space, Row, Col, Input } from 'antd';

import _ from 'lodash';
// import moment from 'moment';

import { withContext } from '@/hoc';
import TABLE from '@/table';

import { ProductFormButton, ProductGroupTree } from '@/component';

export default
@withContext
class Product extends React.Component {
  state = {
    list: [],
    loading: false,
    total: 0,
    current: 1,
    pageSize: 10,

    query: {},
  };
  
  render() {
    const { query } = this.state;

    const { user } = this.props.context;
    return (
      <div style={{padding: 5}}>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <ProductGroupTree editable={!!user} onChange={value => this.setState({ current: 1, query: { ...query, [TABLE.PRODUCT.PRODUCT_GROUP]: value}}, this.refresh)} />
          </Col>
          
          <Col span={18}>
            <Space>
              {!!user && (
                <ProductFormButton value={_.pick(query, [TABLE.PRODUCT.PRODUCT_GROUP])} onFinish={this.onCreate} title="新增数据" okText="新增">新增数据</ProductFormButton>
              )}

              <Input allowClear onChange={({ target: { value }}) => this.setState({ current: 1, query: { ...query, [TABLE.PRODUCT.NAME]: value}}, this.refresh)} placeholder="请输入要搜索的数据" style={{width: 400 }} />
            </Space>
            
            <Table
              style={{marginTop: 5}}
              bordered
              columns={[
                { title: '数据', render: row => (
                  <div>{row[TABLE.PRODUCT.NAME]}<span style={{paddingLeft: 10, fontSize: 12, color: 'gray'}}>{_.map(row[TABLE.PRODUCT.PRODUCT_GROUP], TABLE.PRODUCT_GROUP.NAME).join(' / ')}</span></div>
                )},
                { title: '操作', render: row => (
                  <Space>
                    <ProductFormButton value={{ ...row, [TABLE.PRODUCT.PRODUCT_GROUP]: _.get(_.last(row[TABLE.PRODUCT.PRODUCT_GROUP]), 'id') }} onFinish={this.onUpdate(row)} title="编辑数据" okText="保存">编辑</ProductFormButton>
                  </Space>
                ) },
              ]}
              dataSource={this.state.list}
              loading={this.state.loading}
              pagination={{
                onChange: async (current, pageSize) => this.setState({ current, pageSize }, this.refresh),
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: total => `共 ${total} 条记录`,
                ..._.pick(this.state, ['total', 'current', 'pageSize']),
              }}
              rowKey={row => row.id}
            />
          </Col>
        </Row>
      </div>
    )
  }

  onCreate = async (value, { hideForm }) => {
    try {
      await this.props.context.request(
        ['product.create', value]
      );
      this.props.context.success('创建成功');
      hideForm();
      this.refresh();
    } catch(e) {
      this.props.context.handleException(e);
    }
  }

  onUpdate = ({ id }) => async (value, { hideForm }) => {
    try {
      await this.props.context.request(
        ['product.update', { id }, value]
      );
      this.props.context.success('保存成功');
      hideForm();
      this.refresh();
    } catch(e) {
      this.props.context.handleException(e);
    }
  }

  refresh = async () => {
    try {
      const { query, current, pageSize } = this.state;
      const offset = (current - 1) * pageSize;
      const limit = pageSize;
      this.setState({ loading: true })

      const [list, total] = await this.props.context.request(
        ['product.list', query, { offset, limit }],
        ['product.count', query]
      );

      this.setState({ loading: false, list, total })
    } catch(e) {
      this.setState({ loading: false });
      this.props.context.handleException(e);
    }
  }
  async componentDidMount() {
    await this.refresh()
  }
}